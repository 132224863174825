import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { HtmlMouseButtonEventHandler, HtmlMouseEventHandler } from '@digital-magic/react-common'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodUndefinedIfInvalid } from '@digital-magic/react-common/lib/utils/zod'
import { routes } from '@constants/routes'
import { formatDateShort } from '@utils/date-utils'
import { RequestErrorHandler } from '@api/types'
import { Price } from '@api/endpoints/types'
import {
  BuildingAddress,
  BuildingId,
  HouseId,
  HouseListItem,
  HouseStatus,
  useDeleteHouse,
  useGetBuildings,
  useGetHouses,
  useUpdateHouseStatus
} from '@api/endpoints/buildings/houses'
import { addressSortComparator } from '@api/endpoints/buildings/houses/utils'
import { FacadeColor } from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useDebounce } from '@hooks/useDebounce'
import { useSnackbar } from 'notistack'
import { Add, Delete } from '@mui/icons-material'
import { Box, MenuItem } from '@mui/material'
import { Button, RouterButton } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormTextFieldStyled, useFormZod } from '@controls/Form'
import { MenuTrigger } from '@controls/MenuTrigger'
import { Table, TableColumn } from '@controls/Table'
import { Text } from '@controls/Text'
import { HousesStyled } from '@pages/Admin/Houses/style'
import { HouseFormHistoryState } from './HouseForm/HouseForm'

type BuildingItem = {
  buildingId: BuildingId
  address: BuildingAddress
  facadeColor: FacadeColor
  basePrice: Price
  houses: ReadonlyArray<HouseListItem>
}

const HouseSearchSchema = z.object({
  address: BuildingAddress.optional()
})
type HouseSearchValues = z.TypeOf<typeof HouseSearchSchema>

export const Houses: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const onDeleteHouseError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.house.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const form = useFormZod<HouseSearchValues>({
    schema: HouseSearchSchema
  })

  const address = form.watch('address')
  const debouncedAddress = useDebounce(address, 500)
  const validAddress = zodUndefinedIfInvalid(debouncedAddress, BuildingAddress)

  const deleteHouse = useDeleteHouse({ onError: onDeleteHouseError })
  const getHouses = useGetHouses(validAddress, { onError: defaultErrorHandler })
  const getBuildings = useGetBuildings(validAddress, { onError: defaultErrorHandler })
  const updateHouseStatus = useUpdateHouseStatus({ onError: defaultErrorHandler })

  const housesData = React.useMemo((): ReadonlyArray<BuildingItem> => {
    if (getHouses.data && getBuildings.data) {
      return getBuildings.data
        .map((data) => ({
          buildingId: data.id,
          address: data.address,
          facadeColor: data.facadeColor,
          basePrice: data.basePrice,
          houses: getHouses.data.filter((h) => h.buildingId == data.id)
        }))
        .sort(addressSortComparator)
    } else {
      return []
    }
  }, [getHouses.data, getBuildings.data])

  const onRowClick = (row: HouseListItem): void => {
    navigate(generatePath(routes.Admin.HousesEdit, { houseId: row.id }))
  }

  const onDeleteHouse =
    (id: HouseId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteHouse.mutate(id)
      }
    }

  const onRefreshClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    void getBuildings.refetch()
    void getHouses.refetch()
  }

  const onUpdateStatusMenuItemClick =
    (row: HouseListItem, status: HouseStatus): HtmlMouseEventHandler =>
    () => {
      updateHouseStatus.mutate({
        id: row.id,
        status
      })
    }

  const tableColumns: ReadonlyArray<TableColumn<HouseListItem>> = [
    {
      key: 'cadastreNumber',
      title: t('pages.admin.house.form.field.cadastre_number'),
      sortable: true
    },
    {
      key: 'apartmentNumber',
      title: t('pages.admin.house.form.field.apartment'),
      sortable: true
    },
    {
      key: 'floor',
      title: t('pages.admin.house.form.field.floor'),
      sortable: true
    },
    {
      key: 'roomsCount',
      title: t('pages.admin.house.form.field.rooms'),
      sortable: true
    },
    {
      key: 'basePrice',
      title: t('pages.admin.house.form.field.base_price'),
      sortable: true
    },
    {
      key: 'status',
      title: t('pages.admin.house.form.field.status'),
      sortable: true,
      render: (row) => (
        <MenuTrigger
          renderMenu={() =>
            HouseStatus.options.map((status, index) => (
              <MenuItem key={index} onClick={onUpdateStatusMenuItemClick(row, status)}>
                {t(`enums.booking_status.${status.toLowerCase()}`)}
              </MenuItem>
            ))
          }
        >
          {(setAnchor) => (
            <Button
              size="small"
              onClick={(e) => {
                setAnchor(e.currentTarget)
                e.stopPropagation()
              }}
            >
              {row.status}
            </Button>
          )}
        </MenuTrigger>
      )
    },
    {
      key: 'createdAt',
      title: t('pages.admin.house.form.field.created_at'),
      render: (row) => formatDateShort(row.createdAt)
    },
    {
      key: null,
      title: null,
      render: (row) => (
        <RouterButton
          size="small"
          to={generatePath(routes.Admin.BookingsNew, { houseId: row.id })}
          onClick={(e) => e.stopPropagation()}
        >
          <Add />
        </RouterButton>
      )
    },
    {
      key: null,
      title: null,
      render: (row) => (
        <ButtonWithConfirmation
          size="small"
          color="error"
          onConfirmResult={onDeleteHouse(row.id)}
          confirmTitle={t('global.consent.delete.title')}
          confirmMessage={t('global.consent.delete.message')}
        >
          <Delete />
        </ButtonWithConfirmation>
      )
    }
  ]

  return (
    <HousesStyled>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h1>{t('pages.admin.houses.title')}</h1>
        <form onSubmit={form.handleSubmit(() => false)}>
          <FormTextFieldStyled form={form} name="address" label={t('pages.admin.houses.search.by_address.title')} />
        </form>
      </Box>
      <Box display="flex" flexDirection="column" rowGap={4}>
        {housesData.length == 0 ? (
          <Box className="no-data" textAlign="center">
            <Text size="size-24" weight="light">
              {t('controls.table.messages.no_data_available')}
            </Text>
          </Box>
        ) : (
          housesData.map((data, index) => {
            const createState: HouseFormHistoryState = { buildingId: data.buildingId }

            return (
              <Box key={index} className="house-section">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <div>
                    <div>
                      {t('pages.admin.houses.building.field.address') + ': '}
                      <strong>{data.address}</strong>
                    </div>
                    <div>
                      {t('pages.admin.houses.building.field.facade_color') + ': '}
                      <strong>{data.facadeColor}</strong>
                    </div>
                    <div>
                      {t('pages.admin.houses.building.field.base_price') + ': '}
                      <strong>{data.basePrice}</strong>
                    </div>
                  </div>
                  <RouterButton to={{ pathname: routes.Admin.HousesNew, state: createState }}>
                    {t('pages.admin.houses.buttons.create')}
                  </RouterButton>
                </Box>
                <Table
                  columns={tableColumns}
                  data={data.houses ?? []}
                  onRowClick={onRowClick}
                  initialSortConfig={{ column: 'apartmentNumber', direction: 'ASC' }}
                />
              </Box>
            )
          })
        )}
      </Box>
      <Box display="flex" columnGap={2} mt={2}>
        <RouterButton to={routes.Admin.HousesNew}>{t('global.buttons.add')}</RouterButton>
        <Button onClick={onRefreshClick}>{t('global.buttons.refresh')}</Button>
      </Box>
    </HousesStyled>
  )
}
